import React, { useState, useEffect } from 'react';
// import Index from "./components/UserProfile";
import Profile from './components/UserProfile';
import { getEmergencyContact, getUserProfileData, getProfileImage } from '../Services/ApiCalling';

function ProfileView() {
  const [userData, setuserData] = useState(null);
  const [emgData, setEmgData] = useState()
  
  const token = localStorage.getItem("LogIn_Token")

  const fetchProdileData = async () => {
    const getProfile = await getUserProfileData(token)
    console.log("getProfileDatagetProfileData", getProfile)
    setuserData(getProfile.data)
  }

  const profileImgage = async (token, selectedFile) => {
    if(selectedFile){
      console.log("yash2",selectedFile);
      const profilePic  = await getProfileImage(token, selectedFile);
      console.log(profilePic);
    }
  }

  useEffect(() => {
    profileImgage();
  }, [])

  useEffect(() => {
    fetchProdileData()
  }, [])

  const getEmergencyData = async (token) => {
    const res = await getEmergencyContact(token)
    setEmgData(res.data)
    console.log('respppooooooooooooooooooooo', res.data);
  }
  useEffect(() => {
    getEmergencyData(token);
  }, []);

  return (
    <>
      {userData &&<Profile profileImgage={profileImgage} userData={userData} emgData={emgData} getEmergencyData={getEmergencyData} fetchProdileData={fetchProdileData}/>}
    </>
  );
}

export default ProfileView;
