/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import UserInforModelPopup from "./UserInforModelPopup"
import { noop } from "antd/es/_util/warning";

const Profile = ({fetchProdileData, profileImgage, userData, emgData, getEmergencyData}) => {
  console.log("profiledataSunder", userData, emgData)
  const [desgDetails, setDesgDetails] = useState([]);
  const navigate = useNavigate();

  const token =  localStorage.getItem("LogIn_Token")

  const [loadImg, setLoadImg] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [img_error, setImg_error] = useState('');

  const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  const maxSize = 5 * 1024 * 1024;

  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    setImg_error('');

    if (file && !validImageTypes.includes(file.type)) {
      setImg_error('Please upload a valid image (JPEG, PNG, JPG).');
      return;
    }else if (file && file.size > maxSize) {
      setImg_error('File size exceeds 5MB.');
      return;
    }else{
      setSelectedFile(file);
    }
    
  };

  const Image_error=(err)=>{
    toast.error(err , { position: "top-right" });
  }

  useEffect(() => {
    if (img_error) {
      Image_error(img_error)
      // alert('Error: ' + img_error) 
      setImg_error('');
    }
  }, [img_error]);


const picture = async () =>{
  if(selectedFile){
    const formData = new FormData();
    formData.append('files', selectedFile);
  setLoadImg(true);
  console.log("before api calling");
  await profileImgage(token, formData);
  await fetchProdileData();
  setLoadImg(false);
  console.log("after api calling");
  }
}

  useEffect(()=>{
    picture();
  },[selectedFile])

  const handleLinkClick = () => {
    document.getElementById("file-input").click();
  };

  return (
    <>
    
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">

                        <Link to="#" onClick={handleLinkClick}>
                        {loadImg ? (
                            <>
                              <i className="fas fa-spinner fa-spin me-2" />
                              Uploading...
                            </>
                          ) : (
                            // <>Upload</>
                            ""
                          )}
                          <img src={userData?.EMP_PROFILE_PIC} alt="User Image" 
                            style={{ cursor: "pointer", 
                            filter: loadImg ? 'blur(4px)' : "none" ,
                          }} 
                          />
                          
                        </Link>
                        <input
                          id="file-input"
                          type="file"
                          style={{ display: "none" }} // Hide the file input
                          onChange={handleFileChange} // Handle file selection
                          disabled={loadImg}
                        />
                        <ToastContainer />
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {userData.EMP_NAME}
                            </h3>
                            <h6 className="text-muted">{userData.EMP_ROLE
                            }</h6>
                            <small className="text-muted">
                              {userData.EMP_CURR_DESG
                              }
                            </small>
                            <div className="staff-id">
                              Employee ID : {userData.EMP_ID
                              }
                            </div>
                            <div className="small doj text-muted">
                              Date of Join : {userData.EMP_DOJ}
                            </div>
                            {/* <div className="staff-msg">
                              <Link className="btn btn-custom" to="/call/chat">
                                Send Message
                              </Link>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">
                                <Link to={`tel:${userData.EMP_MOBILE_NO
}`}>
                                  {userData.EMP_MOBILE_NO}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                <Link to={`mailto:${userData.EMP_EMAIL_ID}`}>
                                  {userData.EMP_EMAIL_ID}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">{userData.EMP_DOB}</div>
                            </li>
                            <li>
                              <div className="title">Team:</div>
                              <div className="text">{userData.EMP_TEAM
                              }</div>
                            </li>
                            <li>
                              <div className="title">Role Code:</div>
                              <div className="text">{userData.EMP_ROLE_CODE}</div>
                            </li>
                            <li>
                              <div className="title">Reports to:</div>
                              <div className="text">
                                <Link to="profile">
                                  {userData.EMP_REP_MANG
                                  }
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className="card mb-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">
                                        <div className="profile-basic">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="profile-info-left">
                                                        <h3 className="user-name m-t-0 mb-0">
                                                            Emergency Primary Contact
                                                        </h3><br/>
                                                        <div className="staff-id">
                                                            Employee ID : {emgData ? emgData?.EMP_ID : ""}
                                                        </div>
                                                        <div className="title">Employee Email:</div>
                                                        <div className="text">
                                                            <Link to={`mailto:${emgData ? emgData?.EMP_EMAIL_ID : ""}`}>
                                                                {emgData ? emgData?.EMP_EMAIL_ID : ""}
                                                            </Link>
                                                        </div>
                                                        <div className="small doj text-muted">
                                                            Emg Primary Name : { emgData ? emgData?.EMG_PRIMARY_NAME : ""}
                                                        </div>
                                                        <div className="small doj text-muted">
                                                            Emg Primary Relationship : {emgData ? emgData?.EMG_RELATIONSHIP_PRIMARY : ""}
                                                        </div>
                                                        <div className="small doj text-muted">
                                                            Emg Primary Phone : {emgData ? emgData?.EMG_PHONE_PRIMARY : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <ul className="personal-info">
                                                        <h3 className="user-name m-t-0 mb-0">
                                                            Emergency Secondary Contact
                                                        </h3><br/>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Secondary Name : {emgData ? emgData?.EMG_SECONDARY_NAME : ""}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Secondary Relationship : {emgData ? emgData?.EMG_RELATIONSHIP_SECONDARY : ""}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Secondary Phone No : {emgData ? emgData?.EMG_PHONE_SECONDARY : ""}
                                                            </div>
                                                        </li>
                                                        {/* <li>
                                                            <div className="small doj text-muted">
                                                                Created At : {moment(emgData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Updated At : {moment(emgData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                                                            </div>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pro-edit">
                                            <Link
                                                data-bs-target="#emergency_contact_modal"
                                                data-bs-toggle="modal"
                                                className="edit-icon"
                                                to="#"
                                            >
                                                <i className="fa-solid fa-pencil"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UserInforModelPopup emgData={emgData} getEmergencyData={getEmergencyData} />

          {/* <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>  
                  <li className="nav-item">
                    <Link
                      to="#emp_projects"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Bank &amp; Statutory
                      <small className="text-danger ms-1">(Admin Only)</small>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Assets
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* Profile Info Tab */}
          {/* <ProfileTab /> */}
          
        </div>
      </div>
    </>
  );
};

export default Profile;
