import React, { useState } from "react";
import * as Yup from "yup";

// import { Avatar_02 } from "../../../Routes/ImagePath/index";
// import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import Select from "react-select";
import { Emergency_contact } from "../../Services/ApiCalling"

// Define Yup validation schema
const phoneRegExp = /^(?:\+91|91|0)?[6789]\d{9}$/;
const validationSchema = Yup.object({
  pri_name: Yup.string()
    .min(3, 'Name must be at least 3 characters')
    .max(20, 'Name must not exceed 20 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces')
    .required('name is required'),
  pri_phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .max(10, 'Phone number must be exactly 10 digits')
    .min(10, 'Phone number must be exactly 10 digits')
    .required('phone is required'),
  pri_relation: Yup.string()
  .min(3, 'primary_relation must be at least 3 characters')
  .max(30, 'primary_relation must not exceed 15 characters')
  .matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces')
  .required('primary_relation is required'),
  sec_name: Yup.string()
  .min(3, 'Name must be at least 3 characters')
  .max(20, 'Name must not exceed 20 characters')
  .matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces')
  .required('name is required'),
  sec_phone: Yup.string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(10, 'Phone number must be exactly 10 digits')
  .max(10, 'Phone number must be exactly 10 digits')
  .required('phone is required'),
  sec_relation: Yup.string()
  .min(3, 'seconday_relation must be at least 3 characters')
  .max(30, 'seconday_relation must not exceed 15 characters')
  .matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces')
  .required('seconday_relation is required'),
});

const UserInforModelPopup = ({emgData, getEmergencyData}) => {
  // const [selectedDate1, setSelectedDate1] = useState(null);

  const [formData, setFormData] = useState({
    pri_name: emgData ? emgData?.EMG_PRIMARY_NAME : "",
    pri_phone: emgData ? emgData?.EMG_PHONE_PRIMARY : "",
    pri_relation: emgData ? emgData?.EMG_RELATIONSHIP_PRIMARY : "",
    sec_name: emgData ? emgData?.EMG_SECONDARY_NAME : "",
    sec_phone: emgData ? emgData?.EMG_PHONE_SECONDARY : "",
    sec_relation: emgData ? emgData?.EMG_RELATIONSHIP_SECONDARY : "",
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // const resetValue =()=>{
  //   setFormData({
  //     pri_name: "",
  //     pri_phone: "",
  //     pri_relation: "",
  //     sec_name: "",
  //     sec_phone: "",
  //     sec_relation: "",
  //   })
  // }

  const handleChange = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
    try {
      setErrors({});
      const updatedFormData = { 
        ...formData, 
        [e.target.name]: e.target.value,
      };
      // Validate form data using Yup
      await validationSchema.validate(updatedFormData, { abortEarly: false });
      setSubmitted(true);
  } catch (err) {
    // If validation fails, set error messages
    setSubmitted(false);
    const newErrors = {};
    err.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setErrors(newErrors);
    console.log("err",err);
  }
  };

  const token =  localStorage.getItem("LogIn_Token")

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(submitted){
      const obj_body={
        primary_name : formData.pri_name,
        primary_relation : formData.pri_relation,
        primary_phone : formData.pri_phone,
        secondary_name : formData.sec_name,
        secondary_relation : formData.sec_relation,
        secondary_phone : formData.sec_phone
      }
      await Emergency_contact(token, obj_body);
      await getEmergencyData(token);
      // resetValue();
    }

  }


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
console.log("submitted",submitted);
  return (
    <>
      {/* Emerg contact Info Modal */}
      <div
        id="emergency_contact_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Personal Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">Primary Contact</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" name="pri_name" value={formData.pri_name} required
                          // onChange={(e)=>{setFormData({...formData, [e.target.name]: e.target.value})}}
                          onChange={handleChange}
                          />
                          {errors.pri_name && <div style={{ color: 'red' }}>{errors.pri_name}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            Relationship <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" name="pri_relation" value={formData.pri_relation} required
                          // onChange={(e)=>{setPri_Relation(e.target.value)}}
                          onChange={handleChange}
                          />
                          {errors.pri_relation && <div style={{ color: 'red' }}>{errors.pri_relation}</div>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" name="pri_phone" value={formData.pri_phone} required
                          // onChange={(e)=>{setpri_phone(e.target.value)}}
                          onChange={handleChange}
                          />
                          {errors.pri_phone && <div style={{ color: 'red' }}>{errors.pri_phone}</div>}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">Phone 2</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">Secondary Contact</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" name="sec_name" value={formData.sec_name} required
                          // onChange={(e)=>{setSec_name(e.target.value)}}
                          onChange={handleChange}
                          />
                          {errors.sec_name && <div style={{ color: 'red' }}>{errors.sec_name}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            Relationship <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" name="sec_relation" value={formData.sec_relation} required
                          // onChange={(e)=>{setSec_Relation(e.target.value)}}
                          onChange={handleChange}
                          />
                          {errors.sec_relation && <div style={{ color: 'red' }}>{errors.sec_relation}</div>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" name="sec_phone" value={formData.sec_phone} required
                          // onChange={(e)=>{setSec_phone(e.target.value)}}
                          onChange={handleChange}
                          />
                          {errors.sec_phone && <div style={{ color: 'red' }}>{errors.sec_phone}</div>}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="input-block mb-3 mb-3">
                          <label className="col-form-label">Phone 2</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss= {submitted ? 'modal' : ''}
                    aria-label= {submitted ? 'Close' : ''} 
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Personal Info Modal */}
      {/* <div
        id="personal_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Personal Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">Passport No</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">
                        Passport Expiry Date
                      </label>
                      <div className="cal-icon">
                        <DatePicker
                          selected={selectedDate1}
                          onChange={handleDateChange1}
                          className="form-control floating datetimepicker"
                          type="date"
                          placeholderText="04/10/2023"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">Tel</label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">
                        Nationality <span className="text-danger">*</span>
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">Religion</label>
                      <div className="cal-icon">
                        <DatePicker
                          selected={selectedDate1}
                          onChange={handleDateChange1}
                          className="form-control floating datetimepicker"
                          type="date"
                          placeholderText="04/10/2023"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">
                        Marital status <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={status}
                        placeholder="-"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">
                        Employment of spouse
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3 mb-3">
                      <label className="col-form-label">No. of children </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Personal Info Modal */}

      {/* Education Modal */}
      {/* <div
        id="education_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Education Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Education Informations{" "}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Oxford University"
                              className="form-control floating"
                            />
                            <label className="focus-label">Institution</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">Subject</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Starting Date</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Complete Date</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="BE Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">Degree</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Grade A"
                              className="form-control floating"
                            />
                            <label className="focus-label">Grade</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Education Informations
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Oxford University"
                              className="form-control floating"
                            />
                            <label className="focus-label">Institution</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">Subject</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Starting Date</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Complete Date</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="BE Computer Science"
                              className="form-control floating"
                            />
                            <label className="focus-label">Degree</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              defaultValue="Grade A"
                              className="form-control floating"
                            />
                            <label className="focus-label">Grade</label>
                          </div>
                        </div>
                      </div>
                      <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" /> Add More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Education Modal */}

      {/* Experience Modal */}
      {/* <div
        id="experience_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Experience Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Experience Informations{" "}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="Digital Devlopment Inc"
                            />
                            <label className="focus-label">Company Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="United States"
                            />
                            <label className="focus-label">Location</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="Web Developer"
                            />
                            <label className="focus-label">Job Position</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Period From</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Period To</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Experience Informations{" "}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="Digital Devlopment Inc"
                            />
                            <label className="focus-label">Company Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <input
                              type="text"
                              className="form-control floating"
                              defaultValue="United States"
                            />
                            <label className="focus-label">Location</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <DatePicker
                              selected={selectedDate1}
                              onChange={handleDateChange1}
                              className="form-control floating datetimepicker"
                              type="date"
                              placeholderText="04/10/2023"
                              dateFormat="dd-MM-yyyy"
                            />
                            <label className="focus-label">Job Position</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <DatePicker
                                selected={selectedDate1}
                                onChange={handleDateChange1}
                                className="form-control floating datetimepicker"
                                type="date"
                                placeholderText="04/10/2023"
                                dateFormat="dd-MM-yyyy"
                              />
                            </div>
                            <label className="focus-label">Period From</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3 form-focus focused">
                            <div className="cal-icon">
                              <input
                                type="text"
                                className="form-control floating datetimepicker"
                                defaultValue="08/06/2018"
                              />
                            </div>
                            <label className="focus-label">Period To</label>
                          </div>
                        </div>
                      </div>
                      <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" /> Add More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Experience Modal */}

      {/* Family Info Modal */}
      {/* <div
        id="family_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Family Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Family Member{" "}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Name <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Relationship{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Date of birth{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Phone <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Education Informations{" "}
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Name <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Relationship{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Date of birth{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3 mb-3">
                            <label className="col-form-label">
                              Phone <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="add-more">
                        <Link to="#">
                          <i className="fa-solid fa-plus-circle" /> Add More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Family Info Modal */}
    </>
  );
};

export default UserInforModelPopup;
