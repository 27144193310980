import React, { useEffect, useState } from "react";
import AdminLeave from "./component/AdminLeave";
import { LeaveDetails } from "../Services/ApiCalling";
import { getEmployeeStatsData } from "../Services/ApiCalling";
import moment from "moment";

const AdminLeaveView = () => {
  const [leaveUsers, setLeaveUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [totalRecord, settotalRecord] = useState(null);
  const [totalPage, settotalPage] = useState(null);

  const [page, setPage] = useState(null);

  const token = localStorage.getItem("LogIn_Token");

  const date = new Date(); //yash's change
  let curr_date = date.toISOString(); //yash's change
  let first_date = new Date(date.getFullYear(), date.getMonth() - 1, 1); //yash's change

  const [status, setStatus] = useState();
  const [appType, setAppType] = useState({
    value: "WFH", label: "Work From Home",
  });
  const [appStatus, setAppStatus] = useState({
    value: "PENDING", label: "Pending",
  });
  const [department, setDepartment] = useState({
     value: "SW", label: "Software"
  });
  const [from, setFrom] = useState(first_date?.toISOString()); //yash's change
  const [to, setTo] = useState(curr_date); //yash's change
  const [empName, setEmpName] = useState("");

  // const [fromDate, setFromDate] = useState(first_date.toISOString());
  // const [toDate, setToDate] = useState(curr_date);

  const TYPE = appType?.value;
  const STATUS = appStatus?.value;
  const DEPART = department?.value;
  // const FROM = moment(from)?.add(1, "days").utc().toISOString();
  // const TO = moment(to)?.add(1, "days").utc().toISOString();

  const getLeaveData = async (token, empName, TYPE, STATUS, FROM, TO, DEPART) => {
    const res = await LeaveDetails(token, empName, TYPE, STATUS, FROM, TO, DEPART);
    setCurrentPage(res?.pagination?.currentPage);
    setPageSize(res?.pagination?.pageSize);
    settotalRecord(res?.pagination?.totalRecords);
    settotalPage(res?.pagination?.totalPages);
    setIsLoading(false);
    setLeaveUsers(res.data);

    // const res2 = await getEmployeeStatsData(token)
    // setStatus(res2.data);
  };

  useEffect(() => {
    const leaveData = setTimeout(() => {
      getLeaveData(token, empName, TYPE, STATUS, from, to, DEPART);
    }, 600);
    return () => clearTimeout(leaveData);
  }, [empName, TYPE, STATUS, from, to, DEPART]);

  return (
    <>
      {console.log("ggggggggggg", appType?.value, appStatus?.value, from, to)}
      {
        <AdminLeave
          setEmpName={setEmpName}
          from={from}
          to={to}
          // FROM={FROM}
          // TO={TO}
          STATUS={STATUS}
          empName={empName}
          TYPE={TYPE}
          setAppType={setAppType}
          setAppStatus={setAppStatus}
          setDepartment={setDepartment}
          setFrom={setFrom}
          setTo={setTo}
          isLoading={isLoading}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecord={totalRecord}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          leaveUsers={leaveUsers}
          getLeaveData={getLeaveData}
          emp_sts={status}
        />
      }
    </>
  );
};

export default AdminLeaveView;
