/* eslint-disable no-unused-expressions */

import { Table } from "antd";
import { AddHoliday } from "../../../components/modelpopup/AddHoliday";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { base_url } from "../../../base_urls";
import moment from 'moment';
import { AddHolidays } from "../../Services/ApiCalling";

const Holidays = ({ holidaysData, HolidayData}) => {

  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")
  const [holiday_record, setHoliday_record] = useState();

  const Dec_token = localStorage.getItem("DecodedToken");
  const [user_type, setUser_Type] = useState(null);

  useEffect(()=>{
    if(Dec_token != null){
      setUser_Type(JSON.parse(Dec_token).DESIGNATION_CODE);
      }
  },[])

  const token = localStorage.getItem("LogIn_Token")

  const handleSubmit = async () => {
    const obj = {
      TITLE: title,
      DATE: date,
    };

    try {
      console.log("titletitle", title, date)
      const response = await AddHolidays(token, obj);
      console.log("HolidayDataHolidayData", response)
      await HolidayData(token)
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "ID",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.ID - b.ID,
    },
    {
      title: "Title",
      dataIndex: "TITLE",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.TITLE.length - b.TITLE.length,
    },
    {
      title: "HolidayDate",
      dataIndex: "HOLIDAY_DATE",
      render: (text) => 
        <span>{moment(text).format('YYYY-MM-DD')}</span>,
      sorter: (a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE),
        
    },
    {
      title: "Day",
      dataIndex: "DAY",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.DAY.length - b.DAY.length,
    },
    (user_type === "HR") ? // show Action column based on user_desg_code.
    {
      title: "Action",
      render: (record) => {
        return (
          <div className="dropdown dropdown-action text-end">
            <Link
              to="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-backdrop="static"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                // data-bs-target="#edit_leave"
                data-bs-target="#delete"
                onClick={ async () => {
                  setHoliday_record(record);
                  console.log("yash", record);
                }}
              >
                {/* fa fa-pencil */}
                <i className=" m-r-5" /> &nbsp; Delete
              </Link>
            </div>
          </div>
        );
      },
    }
    : {}
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Holidays"
            title="Dashboard"
            subtitle="Holidays"
            modal="#add_holiday"
            name="Add Holiday"
          />

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {console.log("rashi",holidaysData.length)}
                <Table
                  columns={columns}
                  dataSource={holidaysData?.length > 0 ? holidaysData : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddHoliday setTitle= {setTitle} setDate = {setDate} handleSubmit={handleSubmit}/>
      <DeleteModal Name="Delete Holiday" holiday_record={holiday_record} HolidayData={HolidayData}/>
    </>
  );
};

export default Holidays;
